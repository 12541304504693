import React, { Component } from 'react';
import SwaggerUI from "swagger-ui-react"
import "swagger-ui-react/swagger-ui.css"
import './App.css';
import queryString from 'query-string';
// import  data from './swagger.json'


class App extends Component {
  constructor() {
    super();
    this.documentation = process.env.REACT_APP_API;
    var dev = false;
    var auth = false;
    let devQueryString = ''
    var params = window.location.search.slice(1).split('&')

    const values = queryString.parse(window.location.search);
    console.log(values.doc_url)

    params.forEach(function(value,index){
        if(value.includes('dev=true_')) {
            dev = true;
            devQueryString = value
        }
        if(value === 'auth=true') {
          auth = true;
        }
    });
    if(values.doc_url){
      this.documentation = values.doc_url;
    }
    if(auth) {
      this.documentation = this.documentation+'?auth=true';
    } else if(dev) {
      this.documentation = this.documentation+'?'+devQueryString;
    }
  }
  render() {
    return (
      <SwaggerUI url={this.documentation} docExpansion="none"/>
      // <SwaggerUI spec={data}/>
    );
  }
}
// function App() {
//   return (
//     // <SwaggerUI url="https://s3.amazonaws.com/developers.dev.alphastreet.com/API/swagger/swagger.json"/>
//     <SwaggerUI spec={data}/>
//   );
// }

export default App;
